const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
export const chartValues = (t, data, year) => {
    const values = data.data[year]
    const result = {}

    values.map(item => {
        result[item.month + 1] = item.sum
    })

    return monthList.map((item) => ({
        name: t(`calendar.months.${item}`),
        uv: result[item] || 0,
        percent: result[item] || 0
    }))
}

export const chartTicks = (data = []) => {
    let min = 0
    let max = 1

    for (const value in data) {
        let current = Math.round(Number(data[value].percent))
        if (current < min) {
            min = current
            continue
        }
        if (current > max) {
            max = current
        }
    }

    if ((max - min) >= 10) {
        return [min, max / 2, max]
    }

    return [min, max]
}
