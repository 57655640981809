import Monitoring from "./src/pages/Monitoring/Monitoring";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {apiCheckAccount} from "./api/stat/monitoring";
import Loader from "./src/components/Loader/Loader";
import './index.scss'

function App() {
    const {pathname} = useLocation()
    const [account, setAccount] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const checkAccount = String(pathname).replaceAll('/', '')
        if (!checkAccount) {
            setLoading(false)
        } else {
            apiCheckAccount(checkAccount)
                .then((r) => {
                    if (r.data.result === 'success') {
                        setAccount(checkAccount)
                    } else {
                        setAccount(null)
                    }
                })
                .catch(() => setAccount(null))
                .finally(() => setLoading(false))
        }
    }, [])

    return (
        <div className="app">
            {loading ? <Loader /> : <Monitoring account={account} />}
        </div>
    );
}

export default App;
