import {en, ru} from "../assets/lang/index"

export const lsLang = 'm_ls_ru'

export const getLanguage = () => {
    let currentLang = localStorage.getItem(lsLang)
    if (!currentLang || currentLang !== ru || currentLang !== en) {
        return ru
    }
    return currentLang
}

export const setLanguage = (lang) => {
    if (lang !== ru && lang !== en) {
        return false
    }
    localStorage.setItem(lsLang, lang)
    return true
}
