import React from 'react';
import {useTranslation} from "react-i18next";

function Loader({className}) {
    const {t} = useTranslation()
    return (
        <div className={className || ''}>
            <p>{t('base.loading')}...</p>
        </div>
    );
}

export default Loader;
