import {apiGetActive, apiGetBase, apiGetInfo, apiGetFrozen, apiGetOpen, apiGetTotal} from "./monitoring";

export const apiStat = {
    get: {
        info: apiGetInfo,
        base: apiGetBase,
        total: apiGetTotal,
        active: apiGetActive,
        frozen: apiGetFrozen,
        open: apiGetOpen,
    }
}
