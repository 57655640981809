import React, {useState} from 'react';
import Select from 'react-select';

function YearSelect(props) {
    const {selected, onSelect, options = []} = props
    const [selectedOption, setSelectedOption] = useState(selected);

    const customStyles = {
        control: (provided,state)=>({
            ...provided,
            background: '#051867',
            'borderRadius': '10px',
            'minHeight': '44px',
            'width': (props.width || '151px'),
            border: 'none',
            'boxShadow': 'none',
            'borderBottomLeftRadius': state.menuIsOpen ? '0px' : '10px',
            'borderBottomRightRadius': state.menuIsOpen ? '0px' : '10px',
            cursor :'pointer',
        }),
        dropdownIndicator: (provided)=>({
            ...provided,
            color:'#FFFFFF!important',
            'marginRight': '11px'
        }),
        valueContainer: (provided)=>({
            ...provided,
            'textAlign': 'start',
            'paddingLeft': '23px',
            color:'#FFFFFF!important',

        }),
        placeholder: (provided)=>({
            ...provided,
            color:'#FFFFFF!important'
        }),
        indicatorSeparator: (provided)=>({
            ...provided,
            display: 'none',
        }),
        singleValue: (provided)=>({
            ...provided,
            color: '#FFFFFF',
        }),
        menu: (provided)=>({
            ...provided,
            'marginTop':'0px',
            'borderRadius': '10px',
            'borderTopLeftRadius':'0px',
            'borderTopRightRadius':'0px',
            border:'1px solid #E7E7E7',
            'boxShadow': 'none'
        }),
        option: (provided)=>({
            ...provided,
            'borderBottom':'1px solid #E7E7E7',
            'backgroundColor': 'transparent',
            color: '#272727!important',
            cursor :'pointer'
        }),
    }

    return (
        <Select
            defaultValue={selectedOption}
            styles={customStyles}
            isSearchable={false}
            isDisabled={props.isDisabled}
            onChange={value => {
                setSelectedOption(value)
                onSelect(value.value)
            }}
            options={options}
        />
    )
}

export default YearSelect;
