import React, {useEffect, useState} from 'react';
import i18n from "i18next";
import ruFlag from "./ru.svg"
import usFlag from "./us.svg"
import {setLanguage} from "../../../../storage/localStore";
import './Language.scss';

const RU = 'ru'
const EN = 'en'

function Language(props) {
    const [lang, setLang] = useState(RU)

    useEffect(() => {
        const current = i18n.language
        if (current.includes(RU) && lang !== RU) {
            setLang(RU)
        }
        if (current.includes(EN) && lang !== EN) {
            setLang(EN)
        }
    }, [lang])

    const changeLang = () => {
        const newLang = lang === RU ? EN : RU
        if (setLanguage(newLang)) {
            setLang(newLang)
            i18n.changeLanguage(newLang).then(() => {})
        }
    }

    return (
        <div className={'language'}>
            <p className={'language-selector'} onClick={changeLang}>
                <img src={lang === RU ? ruFlag : usFlag} alt={lang}/>
                <span className={"language-selector-text"}>{lang}</span>
            </p>
        </div>
    );
}

export default Language;
