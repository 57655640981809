import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Block from "../../../components/Block/Block";
import {apiStat} from "../../../../api/stat";
import LogoSvg from './logo.svg';
import WorldSvg from './world.svg';
import './Base.scss';

function Base({account}) {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({
        activePositions: '',
        balance: '',
        drawDown: '',
        funds: '',
        margin: '',
        profit: '',
    })

    const [withdraw, setWithdraw] = useState(0)

    useEffect(() => {
        apiStat.get.base(account)
            .then((response) => {
                setValues(response.data.data.base)
            })
            .catch((error) => console.log(error.response))
            .finally(() => setLoading(false))

        apiStat.get.total(account)
            .then((response) => setWithdraw(response.data.data.total.deposit_minus))
            .catch((error) => console.log(error.response))
    }, [account])

    return (
        <Block className='base' loading={loading}>
            <p className='base-data'>
                {t('stats.base.balance')} <span className='base-data-value'>{values.balance}</span>
            </p>
            <p className='base-data'>
                {t('stats.base.position')} <span className='base-data-value'>{values.activePositions}</span>
            </p>
            <p className='base-data'>
                {t('stats.base.funds')} <span className='base-data-value'>{values.drawDown}</span>
            </p>
            <p className='base-data'>
                {t('stats.base.free')} <span className='base-data-value'>{values.funds}</span>
            </p>
            <p className='base-data'>
                {t('stats.base.profit')} <span className='base-data-value'>{values.profit}</span>
            </p>
            <p className='base-data'>
                {t('stats.base.withdraw')} <span className='base-data-value'>{withdraw} $</span>
            </p>
            <img className='base-world' src={WorldSvg} alt='world' />
            <img className='base-logo' src={LogoSvg} alt='logo' />
        </Block>
    );
}

export default Base;
