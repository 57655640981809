import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Bar, ComposedChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {chartTicks} from "../chartValues";

function Vertical({className, chartData, year}) {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)

    while (loading) {
        setTimeout(() => setLoading(false), 1500)
    }

    return (
        <ResponsiveContainer className={className} width={'100%'} height={'100%'}>
            <ComposedChart
                key={year}
                layout="vertical"
                width={500}
                height={400}
                data={chartData}
                margin={{
                    top: 20,
                    right: 50,
                    bottom: 20,
                    left: 0,
                }}
            >
                <XAxis type="number" display={'number'} allowDataOverflow={true} ticks={chartTicks(chartData)} />⁄
                <YAxis dataKey="name" type="category" />
                <Tooltip formatter={value => `${value} %`}/>
                <Bar
                    name={t('result.percent')}
                    dataKey="uv"
                    barSize={15}
                    fill="#051867"
                    width={100}
                >
                    <LabelList dataKey={'percent'} formatter={value => value ? `${value}%`: ''} position={'right'}/>
                </Bar>
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export default Vertical;
