import axios from 'axios'
import {config} from '../../config'

const statApi = axios.create({
    withCredentials: false,
    baseURL: config.stat_url,
    headers: {
        Authorization: config.stat_token,
    }
})

export {statApi}
