import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Block from "../../../components/Block/Block";
import YearSelect from "./YearSelect/YearSelect";
import Vertical from "./Vertical/Vertical";
import Horizontal from "./Horizontal/Horizontal";
import Loader from "../../../components/Loader/Loader";
import {apiGetResult} from "../../../../api/stat/monitoring";
import {currentYear} from "../../../../tools/date";
import {chartValues} from "./chartValues";
import './ResultChart.scss'

function ResultChart({account}) {
    const {t} = useTranslation()

    const [chartData, setChartData] = useState({})
    const [year, setYear] = useState(currentYear)
    const [yearData, setYearData] = useState([{
        value: currentYear,
        label: currentYear
    }])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        apiGetResult(account)
            .then((r) => {
                setYearData(Object.keys(r.data.data).map(item => ({value: item, label: item})))
                setChartData(r.data)
                setTimeout(() => setLoading(false), 2500)
            })
            .catch((e) => {
                console.log(e.response)
                setLoading(false)
            })
    }, [year])

    return (
        <Block className='result-chart'>
            <div className='result-chart-selector'>
                <p className='result-chart-selector-title'>{t('result.title')} {year}</p>
                <YearSelect
                    selected={{value: year, label: year}}
                    options={yearData}
                    isDisabled={loading}
                    onSelect={value => {
                        setLoading(true)
                        setYear(value)
                        setTimeout(() => setLoading(false), 2500)
                    }}
                />
            </div>
            {loading
                ? <Loader className='result-chart-loader' />
                : <>
                    <Vertical
                        className='result-chart-vertical'
                        year={year}
                        chartData={chartValues(t, chartData, year)}
                    />
                    <Horizontal
                        className='result-chart-horizontal'
                        year={year}
                        chartData={chartValues(t, chartData, year)}
                    />
                </>
            }
        </Block>
    );
}

export default ResultChart;