import React from 'react';
import Loader from "../Loader/Loader";
import './Block.scss';

function Block({className, loading, children}) {
    return (
        <div className={`block ${className || ''}`}>
            {loading ? <Loader /> : children}
        </div>
    );
}

export default Block;
