import {statApi} from "./stat";

export const apiGetInfo = async (account) => {
    return await statApi.get(`/info/${account}/`)
}

export const apiGetBase = async (account) => {
    return await statApi.get(`/base/${account}/`)
}

export const apiGetTotal = async (account) => {
    return await statApi.get(`/total/${account}/`)
}

export const apiGetActive = async (account) => {
    return await statApi.get(`/active/${account}/`)
}

export const apiGetFrozen = async (account) => {
    return await statApi.get(`/frozen/${account}/`)
}

export const apiGetOpen = async (account) => {
    return await statApi.get(`/open/${account}/`)
}

export const apiGetResult = async (account) => {
    return await statApi.get(`/result/${account}/`)
}

export const apiCheckAccount = async (account) => {
    return await statApi.get(`/check/${account}/`)
}
