import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {apiStat} from "../../../../api/stat";
import Block from "../../../components/Block/Block";
import './Info.scss'

function Info({account}) {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({
        server: '',
        credit: '',
        robot: '',
        version: '',
    })

    useEffect(() => {
        apiStat.get.info(account)
            .then((response) => {
                setValues(response.data.data.info)
            })
            .catch((error) => console.log(error.response))
            .finally(() => setLoading(false))
    }, [account])

    return (
        <Block className='info' loading={loading}>
            <p className='info-stat'>
                {t('stats.info.server')} <span className='info-stat-value'>{values.server}</span>
            </p>
            <p className='info-stat'>
                {t('stats.info.credit')} <span className='info-stat-value'>{values.credit}</span>
            </p>
            <p className='info-stat'>
                {t('stats.info.robot')} <span className='info-stat-value'>{values.robot}</span>
            </p>
            <p className='info-stat'>
                {t('stats.info.version')} <span className='info-stat-value'>{values.version}</span>
            </p>
        </Block>
    );
}

export default Info;
