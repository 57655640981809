import React from 'react';
import Info from "./Info/Info";
import Base from "./Base/Base";
import ResultChart from "./ResultChart/ResultChart";
import Language from "./Language/Language";
import './Monitoring.scss';

function Monitoring({account}) {
    // Доп чеккер, чтобы проверить существует аккаунт или нет

    if (!account) {
        return <div className='monitoring'>
            <p>Аккаунт не найден в системе. Возможно неправильно ввели данные?</p>
        </div>
    }

    return (
        <div className='monitoring'>
            <Language />
            <Info account={account} />
            <Base account={account} />
            <ResultChart account={account} />
        </div>
    );
}

export default Monitoring;
