import i18n from 'i18next';
import {initReactI18next} from "react-i18next";
import ruFile from "./translate/ru.json"
import enFile from "./translate/en.json"

export const ru = 'ru'
export const en = 'en'

const current = localStorage.getItem('m_ls_ru')

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            ru: {
                translation: ruFile
            },
            en: {
                translation: enFile
            }
        },
        lng: current,
        fallbackLng: ru,
        interpolation: {
            escapeValue: false
        }
    }).then(() => {});
